import {
    REQUEST_PRODUCTS,
    RECEIVE_PRODUCTS,
    ERROR_PRODUCTS
} from '../../types'
import {initialFetchArrayState} from "../../../constants/defaultValues"
import {loadDataRefresh} from "../../../constants/constants"

export default (state = initialFetchArrayState, action = {}) => {
    switch(action.type) {
        case REQUEST_PRODUCTS:
            return {
                ...state,
                isFetching: true,
                message: '',
                errors: {}
            }
        case RECEIVE_PRODUCTS:
            return {
                ...state,
                isFetching: false,
                data: action.payload,
                errors: {},
                message: '',
                validUntil: loadDataRefresh.frontendNever + Date.now()
            }
        case ERROR_PRODUCTS:
            return {
                ...state,
                isFetching: false,
                errors: action.errors,
                message: action.errors.server || '',
            }
        default:
            return state
    }
}