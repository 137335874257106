import {
    REQUEST_HELP,
    RECEIVE_HELP,
    ERROR_HELP
} from '../../types'
import _cloneDeep from 'lodash/cloneDeep'
import {initialFetchState} from "../../../constants/defaultValues"
import {loadDataRefresh} from "../../../constants/constants"


export default (state = {}, action = {}) => {
    const newState = _cloneDeep(state)

    switch(action.type) {
        case REQUEST_HELP:
            newState[action.identifier] = {
                ...initialFetchState,
                isFetching: true
            }
            return newState
        case RECEIVE_HELP:
            newState[action.identifier] = {
                ...newState[action.identifier],
                isFetching: false,
                data: action.payload,
                message: '',
                errors: {},
                validUntil: loadDataRefresh.frontendNever + Date.now()
            }
            return newState
        case ERROR_HELP:
            newState[action.identifier] = {
                ...newState[action.identifier],
                isFetching: false,
                data: {},
                message: action.errors.server || '',
                errors: action.errors
            }
            return newState
        default:
            return state
    }
}