import {
    REQUEST_CURRENT_ADMIN,
    RECEIVE_CURRENT_ADMIN,
    ERROR_CURRENT_ADMIN,
    INIT_CURRENT_ADMIN,
} from '../../types'
import {initialUserFetchState} from '../../../constants/defaultValues'
import isEmpty from "lodash/isEmpty"

export default (state = initialUserFetchState, action) => {
    switch (action.type) {
        case REQUEST_CURRENT_ADMIN:
            return {
                ...state,
                isFetching: true,
                isAuthenticated: true,
                //user: {}
            }
        case ERROR_CURRENT_ADMIN:
            return {
                ...state,
                isFetching: true,
                isAuthenticated: false,
                user: {}
            }
        case RECEIVE_CURRENT_ADMIN:
            return {
                ...state,
                isFetching: false,
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload,
            }
        case INIT_CURRENT_ADMIN:
            return {
                ...state,
                isFetching: true
            }
        default:
            return state
    }
}
