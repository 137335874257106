import {
    REQUEST_FOOTER_DOCUMENTS,
    RECEIVE_FOOTER_DOCUMENTS,
    ERROR_FOOTER_DOCUMENTS
} from '../../types'
import {initialFetchState} from "../../../constants/defaultValues"
import {loadDataRefresh} from "../../../constants/constants"

export default (state = initialFetchState, action = {}) => {
    switch(action.type) {
        case REQUEST_FOOTER_DOCUMENTS:
            return {
                ...state,
                isFetching: true,
                message: '',
                errors: {}
            }
        case RECEIVE_FOOTER_DOCUMENTS:
            return {
                ...state,
                isFetching: false,
                data: action.payload,
                errors: {},
                message: '',
                validUntil: loadDataRefresh.frontendNever + Date.now()
            }
        case ERROR_FOOTER_DOCUMENTS:
            return {
                ...state,
                isFetching: false,
                errors: action.errors,
                message: action.errors.server || '',
            }
        default:
            return state
    }
}