import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import "bootstrap/dist/css/bootstrap.min.css"
import "rc-switch/assets/index.css"
import 'react-image-lightbox/style.css'
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css'

import {storage} from "./constants/defaultValues"

try {
    localStorage.getItem(storage.cookie_settings)
} catch (e) {
    alert("Váš prohlížeč blokuje cookies které jsou potřebné pro správnou funkci stránky. Povolte cookies a aktualizujte stránku.")
}

let render = () => {
    require('./AppRenderer')
}
render()
