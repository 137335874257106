import {
    SET_FILTER_DATA,
    TOGGLE_FILTER
} from '../../types'
import {storage} from "../../../constants/defaultValues"

const INIT_STATE = localStorage.getItem(storage.filter) ? JSON.parse(localStorage.getItem(storage.filter)) : {}

export default (state = INIT_STATE, action) => {
    const newState = {...state}
    if(!newState[action.filterType]){
        newState[action.filterType] = {
            isOpen: false,
            filter: {},
            order: {}
        }
    }
    switch (action.type) {
        case TOGGLE_FILTER:
            newState[action.filterType] = {
                ...newState[action.filterType],
                isOpen: !newState[action.filterType].isOpen,
                fromLC: true
            }
            localStorage.setItem(storage.filter, JSON.stringify(newState))
            newState[action.filterType].fromLC = false
            return newState
        case SET_FILTER_DATA:
            newState[action.filterType] = {
                ...newState[action.filterType],
                filter: action.setOrder ? newState[action.filterType].filter : action.payload,
                order: action.setOrder ? action.payload : newState[action.filterType].order,
                fromLC: true
            }
            localStorage.setItem(storage.filter, JSON.stringify(newState))
            newState[action.filterType].fromLC = false
            return newState
        default:
            return state
    }
}