import {
    REQUEST_INFO,
    RECEIVE_INFO,
    ERROR_INFO
} from '../../types'
import {initialFetchState} from '../../../constants/defaultValues'

export default (state = initialFetchState, action = {}) => {
    switch(action.type) {
        case REQUEST_INFO:
            return {
                ...state,
                isFetching: true,
                message: '',
                errors: {}
            }
        case RECEIVE_INFO:
            return {
                ...state,
                isFetching: false,
                data: action.payload,
                errors: {},
                message: action.message,
            }
        case ERROR_INFO:
            return {
                ...state,
                isFetching: false,
                errors: action.errors
            }
        default:
            return state
    }
}