import {
    REQUEST_ITEM,
    RECEIVE_ITEM,
    UPDATE_ITEM,
    ERROR_ITEM
} from '../../types'
import {initialFetchState} from '../../../constants/defaultValues'
import _isObject from 'lodash/isObject'
import _set from "lodash/set"
import {loadDataRefresh} from "../../../constants/constants"

export default (state = initialFetchState, action = {}) => {
    switch(action.type) {
        case REQUEST_ITEM:
            return {
                ...state,
                isFetching: true,
                key: action.payload,
                message: '',
                errors: {}
            }
        case RECEIVE_ITEM:
            const newData = {
                ...state,
                isFetching: false,
                errors: {},
                message: action.message,
                tabDataValidUntil: undefined
            }
            if(action.patch){
                newData.data = {...state.data, ...action.payload}
            }else{
                newData.data = action.payload
            }
            return newData
        case UPDATE_ITEM:
            const data = state.data
            switch(action.updateType) {
                case 'loginLog':
                    data.logs.login = action.payload
                    break
                default:
                    _set(data, action.updateType, action.payload)
            }

            let isFetching = state.isFetching
            if(action.stopFetching){
                isFetching = false
            }
            let tabDataValidUntil = state.tabDataValidUntil || {}
            if(action.setValidity){
                tabDataValidUntil[action.updateType] = loadDataRefresh.itemTabData + Date.now()
            }

            return {
                ...state,
                data,
                isFetching,
                tabDataValidUntil
            }
        case ERROR_ITEM:
            return {
                ...state,
                isFetching: false,
                errors: action.payload,
                message: _isObject(action.payload) && action.payload.server ? action.payload.server : ''
            }
        default:
            return state
    }
}