import {
    SET_PERSISTENT_FILTERS,
    INIT_PERSISTENT_FILTERS,
} from '../../types'
import _cloneDeep from "lodash/cloneDeep"
import _set from "lodash/set"

export default (state = {}, action = {}) => {
    const newState = _cloneDeep(state)

    switch(action.type) {
        case SET_PERSISTENT_FILTERS:
            _set(newState, [action.identifier, action.name], action.value)
            return newState
        case INIT_PERSISTENT_FILTERS:
            newState[action.identifier] = _cloneDeep(action.payload)
            return newState
        default:
            return state
    }
}