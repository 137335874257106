import {
    REQUEST_COOKIES,
    RECEIVE_COOKIES,
    ERROR_COOKIES
} from '../../types'
import {initialFetchArrayState} from "../../../constants/defaultValues"

export default (state = initialFetchArrayState, action = {}) => {
    const d = new Date()

    switch(action.type) {
        case REQUEST_COOKIES:
            return {
                ...state,
                isFetching: true,
                message: '',
                errors: {}
            }
        case RECEIVE_COOKIES:
            return {
                ...state,
                isFetching: false,
                data: action.payload,
                errors: {},
                message: ''
            }
        case ERROR_COOKIES:
            return {
                ...state,
                isFetching: false,
                errors: action.errors,
                message: action.errors.server || '',
            }
        default:
            return state
    }
}