import {
    REQUEST_ADVICES_LIST,
    RECEIVE_ADVICES_LIST,
    ERROR_ADVICES_LIST
} from '../../types'
import {initialFetchArrayState} from "../../../constants/defaultValues"
import {loadDataRefresh} from "../../../constants/constants"

export default (state = initialFetchArrayState, action = {}) => {
    switch(action.type) {
        case REQUEST_ADVICES_LIST:
            return {
                ...state,
                isFetching: true,
                message: '',
                errors: {}
            }
        case RECEIVE_ADVICES_LIST:
            return {
                ...state,
                isFetching: false,
                data: action.payload,
                errors: {},
                message: '',
                validUntil: loadDataRefresh.frontendNormal + Date.now()
            }
        case ERROR_ADVICES_LIST:
            return {
                ...state,
                isFetching: false,
                errors: action.errors,
                message: action.errors.server || '',
            }
        default:
            return state
    }
}