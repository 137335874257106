import {
    REQUEST_LAZY_ENUMS,
    RECEIVE_LAZY_ENUMS,
    ERROR_LAZY_ENUMS
} from '../../types'
import {initialEnumItemFetchState} from "../../../constants/defaultValues"
import _get from "lodash/get"
import _cloneDeep from 'lodash/cloneDeep'

export default (state = {}, action = {}) => {
    const newState = _cloneDeep(state)

    switch(action.type) {
        case REQUEST_LAZY_ENUMS:
            newState[action.identifier] = {
                ...initialEnumItemFetchState,
                data: _get(newState, [action.identifier, 'data'], {}),
                isFetching: true
            }
            return newState
        case RECEIVE_LAZY_ENUMS:
            newState[action.identifier] = {
                ...newState[action.identifier],
                isFetching: false,
                data: action.payload,
                shouldReload: false,
                message: '',
                errors: {}
            }
            return newState
        case ERROR_LAZY_ENUMS:
            newState[action.identifier] = {
                ...newState[action.identifier],
                isFetching: false,
                data: {},
                message: action.errors.server || '',
                errors: action.errors
            }
            return newState
        default:
            return state
    }
}