import {
    REQUEST_BLOG_LIST,
    RECEIVE_BLOG_LIST,
    UPDATE_BLOG_LIST,
    ERROR_BLOG_LIST,
    RELOAD_BLOG_LIST
} from '../../types'
import {initialFetchState} from '../../../constants/defaultValues'
import _cloneDeep from "lodash/cloneDeep"
import _set from "lodash/set"
import _get from "lodash/get"
import {loadDataRefresh} from "../../../constants/constants"
import _isEmpty from "lodash/isEmpty"

function modify(items, article, favouriteSection = false){
    const index = items.findIndex(item => item.id == article.id)
    if(favouriteSection){
        if(article.favourite){
            items.unshift(article)
        }else{
            items.splice(index, 1)
        }
    }else{
        if(index > -1){
            items[index] = {...items[index], ...article}
        }
    }
}

export default (state = {}, action = {}) => {
    const newState = _cloneDeep(state)
    const {product, category} = action

    switch(action.type) {
        case REQUEST_BLOG_LIST:
            _set(newState, [product, category], {
                ...initialFetchState,
                data: _get(newState, [product, category, 'data'], {}),
                isFetching: true
            })
            return newState
        case RECEIVE_BLOG_LIST:
            const data = action.payload
            if(!_isEmpty(newState[product][category].data) && category != "homepage" && action.concatenate){
                data.items = newState[product][category].data.items.concat(data.items)
            }
            _set(newState, [product, category], {
                ...newState[product][category],
                isFetching: false,
                data,
                message: '',
                errors: {},
                validUntil: loadDataRefresh.frontendNormal + Date.now()
            })
            return newState
        case UPDATE_BLOG_LIST:
            if(action.updateType == 'favourite'){
                Object.keys(newState).map(product => {
                    Object.keys(newState[product]).map(section => {
                        if(section == 'homepage'){
                            Object.keys(newState[product][section].data).map(homeSection => {
                                if(homeSection == 'categories'){
                                    Object.keys(newState[product][section].data[homeSection]).map(category => {
                                        modify(newState[product][section].data[homeSection][category], action.payload)
                                    })
                                }else if(homeSection == 'favourite'){
                                    modify(newState[product][section].data[homeSection], action.payload, true)
                                }else if(homeSection == 'newest'){
                                    modify(newState[product][section].data[homeSection], action.payload)
                                }
                            })
                        }else{ //category list
                            modify(newState[product][section].data.items, action.payload, section == "favourite")
                        }
                    })
                })
            }else if(action.updateType == 'comments'){
                Object.keys(newState).map(product => {
                    Object.keys(newState[product]).map(section => {
                        if(section != 'homepage'){
                            const index = _get(newState[product][section], "data.items", []).findIndex(a => a.slug == action.slug)
                            if(index > -1){
                                newState[product][section].data.items[index].comments_cnt = action.payload
                            }
                        }
                    })
                })
            }
            return newState
        case RELOAD_BLOG_LIST:
            Object.keys(newState).map(product => {
                Object.keys(newState[product]).map(section => {
                    newState[product][section].shouldReload = true
                })
            })
            return newState
        case ERROR_BLOG_LIST:
            _set(newState, [product, category], {
                ...newState[product][category],
                isFetching: false,
                data: {},
                message: action.errors.server || '',
                errors: action.errors
            })
            return newState
        default:
            return state
    }
}