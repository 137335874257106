import {
    REQUEST_SUPPORT_LIST,
    RECEIVE_SUPPORT_LIST,
    UPDATE_SUPPORT_LIST,
    ERROR_SUPPORT_LIST,
    RELOAD_SUPPORT_LIST
} from '../../types'
import {initialFetchState} from '../../../constants/defaultValues'
import _cloneDeep from "lodash/cloneDeep"
import _set from "lodash/set"
import _get from "lodash/get"
import {loadDataRefresh} from "../../../constants/constants"
import _isEmpty from "lodash/isEmpty"

export default (state = {}, action = {}) => {
    const newState = _cloneDeep(state)
    const identifier = action.identifier

    switch(action.type) {
        case REQUEST_SUPPORT_LIST:
            _set(newState, identifier, {
                ...initialFetchState,
                data: _get(newState, [identifier, 'data'], {}),
                isFetching: true
            })
            return newState
        case RECEIVE_SUPPORT_LIST:
            const data = action.payload
            if(!_isEmpty(newState[identifier].data) && action.concatenate){
                data.items = newState[identifier].data.items.concat(data.items)
            }
            _set(newState, identifier, {
                ...newState[identifier],
                isFetching: false,
                data,
                message: '',
                errors: {},
                validUntil: loadDataRefresh.frontendNormal + Date.now()
            })
            return newState
        case UPDATE_SUPPORT_LIST:
            if(action.updateType == 'favourite'){
                Object.keys(newState).map(ident => {
                    const a = action.payload
                    const index = newState[ident].data.items.findIndex(aa => aa.id == a.id)
                    if(index > -1){
                        if(!a.favourite && ident.includes("favourite=true")){
                            newState[ident].data.items.splice(index, 1)
                        }else{
                            newState[ident].data.items[index] = {...newState[ident].data.items[index], ...a}
                        }
                    }
                })
            }else if(action.updateType == 'comments'){
                Object.keys(newState).map(ident => {
                    const index = _get(newState[ident], "data.items", []).findIndex(a => a.slug == action.slug)
                    if(index > -1){
                        newState[ident].data.items[index].comments_cnt = action.payload
                    }
                })
            }
            return newState
        case RELOAD_SUPPORT_LIST:
            Object.keys(newState).map(ident => {
                if(action.favourite && ident.includes("favourite=true")){
                    newState[ident].shouldReload = true
                }else{
                    newState[ident].shouldReload = true
                }
            })
            return newState
        case ERROR_SUPPORT_LIST:
            _set(newState, identifier, {
                ...newState[identifier],
                isFetching: false,
                data: {},
                message: action.errors.server || '',
                errors: action.errors
            })
            return newState
        default:
            return state
    }
}