import {
    REQUEST_BENEFITS,
    RECEIVE_BENEFITS,
    UPDATE_BENEFITS,
    ERROR_BENEFITS
} from '../../types'
import {initialFetchArrayState} from "../../../constants/defaultValues"
import _cloneDeep from "lodash/cloneDeep";

export default (state = initialFetchArrayState, action = {}) => {
    const d = new Date()

    switch(action.type) {
        case REQUEST_BENEFITS:
            return {
                ...state,
                isFetching: true,
                message: '',
                errors: {}
            }
        case UPDATE_BENEFITS:
            const newState = _cloneDeep(state)
            newState.data.benefits = action.payload
            return newState
        case RECEIVE_BENEFITS:
            return {
                ...state,
                isFetching: false,
                data: action.payload,
                errors: {},
                message: ''
            }
        case ERROR_BENEFITS:
            return {
                ...state,
                isFetching: false,
                errors: action.errors,
                message: action.errors.server || '',
            }
        default:
            return state
    }
}