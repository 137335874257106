import {
    REQUEST_CURRENT_USER,
    RECEIVE_CURRENT_USER,
    ERROR_CURRENT_USER,
    UPDATE_CURRENT_USER
} from '../../types'
import {initialUserFetchState} from '../../../constants/defaultValues'
import isEmpty from "lodash/isEmpty"
import _cloneDeep from "lodash/cloneDeep"
import _set from "lodash/set";

export default (state = initialUserFetchState, action) => {
    switch (action.type) {
        case REQUEST_CURRENT_USER:
            return {
                ...state,
                isFetching: true,
                isAuthenticated: true,
                isLimited: false,
                user: {}
            }
        case ERROR_CURRENT_USER:
            return {
                ...state,
                isFetching: true,
                isAuthenticated: false,
                isLimited: false,
                user: {}
            }
        case RECEIVE_CURRENT_USER:
            return {
                ...state,
                isFetching: false,
                user: action.payload,
                isAuthenticated: !isEmpty(action.payload),
                isLimited: action.payload.limited
            }
        case UPDATE_CURRENT_USER:
            let user = _cloneDeep(state.user)
            switch (action.updateType){
                case "responsible":
                case "sms":
                    user = {...user, ...action.payload}
                    break
                default:
                    user[action.updateType] = action.payload
            }
            return {
                ...state,
                user
            }
        default:
            return state
    }
}
