import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import {configureStore} from './redux/store'
import SuspenseFallback from "./components/SuspenseFallback"

const App = React.lazy(() => import('./App' ))

ReactDOM.render(
  <Provider store={configureStore()}>
    <Suspense fallback={<SuspenseFallback/>}>
      <App />
    </Suspense>
  </Provider>,
  document.getElementById('root')
);
/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
//serviceWorker.unregister();
