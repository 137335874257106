import {
    REQUEST_ADMIN_BLOG_CATEGORIES,
    RECEIVE_ADMIN_BLOG_CATEGORIES,
    UPDATE_ADMIN_BLOG_CATEGORIES,
    ERROR_ADMIN_BLOG_CATEGORIES
} from '../../types'
import {initialFetchState} from '../../../constants/defaultValues'
import {loadDataRefresh} from "../../../constants/constants";

export default (state = initialFetchState, action = {}) => {
    switch(action.type) {
        case REQUEST_ADMIN_BLOG_CATEGORIES:
            return {
                ...state,
                isFetching: true,
                message: '',
                errors: {}
            }
        case RECEIVE_ADMIN_BLOG_CATEGORIES:
            return {
                ...state,
                isFetching: false,
                data: action.payload,
                errors: {},
                message: '',
                validUntil: loadDataRefresh.default + Date.now(),
            }
        case UPDATE_ADMIN_BLOG_CATEGORIES:
            const data = state.data
            let index = null
            switch(action.updateType) {
                case 'create':
                    data.items.unshift(action.payload)
                    break
                case 'update':
                    if(data.items){
                        index = data.items.findIndex(item => item.id == action.id)
                        data.items[index] = action.payload
                    }
                    break
                case 'patch':
                    index = data.items.findIndex(item => item.id == action.id)
                    data.items[index] = {...data.items[index], ...action.payload}
                    break
                case 'delete':
                    index = data.items.findIndex(item => item.id == action.id)
                    data.items.splice(index, 1)
                    break
            }
            return {
                ...state,
                isFetching: false,
                data,
                errors: {}
            }
        case ERROR_ADMIN_BLOG_CATEGORIES:
            return {
                ...state,
                isFetching: false,
                errors: action.errors,
                message: action.errors.server || '',
            }
        default:
            return state
    }
}