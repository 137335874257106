import {
    REQUEST_SMS,
    RECEIVE_SMS,
    ERROR_SMS
} from '../../types'
import {initialFetchState} from '../../../constants/defaultValues'
import {loadDataRefresh} from "../../../constants/constants";

export default (state = initialFetchState, action = {}) => {
    switch(action.type) {
        case REQUEST_SMS:
            return {
                ...state,
                isFetching: true,
                message: '',
                errors: {}
            }
        case RECEIVE_SMS:
            return {
                ...state,
                isFetching: false,
                data: action.payload,
                errors: {},
                message: action.message,
                validUntil: loadDataRefresh.frontendNever + Date.now()
            }
        case ERROR_SMS:
            return {
                ...state,
                isFetching: false,
                errors: action.errors
            }
        default:
            return state
    }
}