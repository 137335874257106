import {
    REQUEST_ADVICE_QAA,
    RECEIVE_ADVICE_QAA,
    UPDATE_ADVICE_QAA,
    ERROR_ADVICE_QAA
} from '../../types'
import {initialFetchArrayState} from "../../../constants/defaultValues"
import {loadDataRefresh} from "../../../constants/constants"
import _cloneDeep from "lodash/cloneDeep"

export default (state = initialFetchArrayState, action = {}) => {
    switch(action.type) {
        case REQUEST_ADVICE_QAA:
            return {
                ...state,
                isFetching: true,
                message: '',
                errors: {}
            }
        case RECEIVE_ADVICE_QAA:
            return {
                ...state,
                isFetching: false,
                data: action.payload,
                errors: {},
                message: '',
                validUntil: loadDataRefresh.frontendNormal + Date.now()
            }
        case UPDATE_ADVICE_QAA:
            const newState = _cloneDeep(state)
            if(action.updateType == "question"){
                newState.data.unshift(action.payload)
            }else{
                const index = newState.data.findIndex(d => d.id == action.id)
                if(index > -1){
                    newState.data[index].status = action.payload.status
                    newState.data[index].answers.push(action.payload.answer)
                }
            }
            return newState
        case ERROR_ADVICE_QAA:
            return {
                ...state,
                isFetching: false,
                errors: action.errors,
                message: action.errors.server || '',
            }
        default:
            return state
    }
}