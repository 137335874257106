import {
    REQUEST_INVOICES,
    RECEIVE_INVOICES,
    ERROR_INVOICES
} from '../../types'
import _cloneDeep from 'lodash/cloneDeep'
import {initialFetchState} from "../../../constants/defaultValues"
import {loadDataRefresh} from "../../../constants/constants"
import _isEmpty from "lodash/isEmpty"
import _set from "lodash/set"
import _get from "lodash/get"


export default (state = {}, action = {}) => {
    const newState = _cloneDeep(state)
    const module = action.module
    switch(action.type) {
        case REQUEST_INVOICES:
            _set(newState, module, {
                ...initialFetchState,
                data: _get(newState, [module, 'data'], {}),
                isFetching: true
            })
            return newState
        case RECEIVE_INVOICES:
            const data = action.payload
            if(!_isEmpty(newState[module].data) && action.concatenate){
                data.items = newState[module].data.items.concat(data.items)
            }
            _set(newState, module, {
                ...newState[module],
                isFetching: false,
                data,
                message: '',
                errors: {},
                validUntil: loadDataRefresh.frontendNormal + Date.now()
            })
            return newState
        case ERROR_INVOICES:
            _set(newState, module, {
                ...newState[module],
                isFetching: false,
                data: {},
                message: action.errors.server || '',
                errors: action.errors
            })
            return newState
        default:
            return state
    }
}